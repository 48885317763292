.city-modal-container {
  padding: 15px 40px;
  background-color: #fff;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
  background-color: #424242;
  color: #ffffff;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.edit-city-name-input {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.city-textarea-input {
  width: 100%;
  height: 120px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #333333;
  color: #ffffff;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.close-button,
.delete-button {
  padding: 10px 15px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.save-button {
  background-color: #3498db;
}

.close-button {
  background-color: #ff6f61;
}

.delete-button {
  background-color: #e74c3c;
}

.close-button:hover {
  background-color: #5a6268;
}

@media (max-width: 768px) {
  .modal-container {
    width: 90%;
  }

  h2 {
    font-size: 20px;
  }

  .textarea-input {
    font-size: 12px;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }

  .save-button,
  .close-button {
    width: 100%;
  }
}

/* slider styles */

/* Custom styles for carousel arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block !important; /* Ensure the arrows are displayed */
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  z-index: 999 !important; /* Ensure it's above other elements */
  cursor: pointer;
  color: #333 !important; /* Ensure the arrows are visible */
}

.slick-prev::before,
.slick-next::before {
  font-size: 30px !important;
  opacity: 1 !important; /* Make the arrows visible */
  color: #333 !important; /* Ensure the arrows are visible */
}

.slick-prev:hover,
.slick-next:hover {
  color: #000 !important;
}

.slick-prev::before {
  content: "←" !important; /* Left arrow */
}
.slick-next::before {
  content: "→" !important; /* Right arrow */
}

.image-container {
  position: relative;
}
