.App {
  text-align: center;
  font-family: monospace;
  color: #ffffff;
  background-color: #1c1c1e;
  height: 20%;
}

.title {
  background-color: #1c1c1e;
  color: #ffffff;
}

.login-container {
  display: flex; /* Show login form */
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align */
  width: 50%; /* Full width on mobile */
  margin: 0 auto; /* Center the container */
  padding: 10px; /* Padding for better spacing */
  background-color: #3c3c3e;
  border-radius: 8px; /* Rounded corners */
}

/* Container for login/logout button */

/* Style for the logout button */
.logout-button {
  padding: 10px 20px;
  background-color: #ff4b4b;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #ff3333;
}

/* Burger icon styles */
.burger-icon {
  display: none; /* Hidden by default */
  cursor: pointer;
  position: absolute;
  top: 10px; /* Adjust position */
  right: 10px; /* Adjust position */
}

/* Style for the lines of the burger icon */
.burger-icon .line {
  width: 30px; /* Width of each line */
  height: 4px; /* Height of each line */
  background-color: #ffffff; /* Color of the lines */
  margin: 4px 0; /* Spacing between lines */
  transition: all 0.3s ease; /* Optional transition effect */
}
@media (max-width: 480px) {
  .App {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
  }

  .App .login-container {
    display: flex; /* Show login form */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align */
    width: 50%; /* Full width on mobile */
    margin: 0 auto; /* Center the container */
    padding: 10px; /* Padding for better spacing */
    border-radius: 8px; /* Rounded corners */
  }

  .App .login-form {
    flex-direction: column;
  }
  .title {
    font-size: 9px;
  }
  .burger-icon {
    display: flex; /* Show burger icon on mobile */
    flex-direction: column;
  }

  .globeContainer {
    width: 100%; /* Full width for the globe */
    height: 200px; /* Set a fixed height for the globe */
  }

  .travelStats,
  .countryStatus {
    width: 90%; /* Allow for some margins */
    padding: 10px; /* Padding for better touch targets */
    margin-top: 10px; /* Space between elements */
    background: #333; /* Background for contrast */
    border-radius: 5px; /* Rounded corners */
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .App {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
  }

  .App .login-container {
    display: flex; /* Show login form */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align */
    width: 50%; /* Full width on mobile */
    margin: 0 auto; /* Center the container */
    padding: 10px; /* Padding for better spacing */
    background-color: #3c3c3e;
    border-radius: 8px; /* Rounded corners */
  }

  .App .login-form {
    flex-direction: column;
  }
  .title {
    font-size: 9px;
  }
  .burger-icon {
    display: flex; /* Show burger icon on mobile */
    flex-direction: column;
    /* margin-right: 10px; */
  }

  .globeContainer {
    width: 100%; /* Full width for the globe */
    height: 200px; /* Set a fixed height for the globe */
  }

  .travelStats,
  .countryStatus {
    width: 90%; /* Allow for some margins */
    padding: 10px; /* Padding for better touch targets */
    margin-top: 10px; /* Space between elements */
    background: #333; /* Background for contrast */
    border-radius: 5px; /* Rounded corners */
  }
}
/*  */
