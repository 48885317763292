.modal-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #424242;
  color: #ffffff;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #ffffff;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.textarea-input {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.add-button,
.toggle-button,
.close-button {
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.toggle-button {
  background-color: #f39c12;
}

.close-button {
  background-color: #ff6f61;
}
.add-button {
  background-color: #28a745;
  margin-left: 10px;
  /* display: flex; */
}

.add-city-input {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  background-color: #333333;
  color: #ffffff;
}

button:hover {
  opacity: 0.8;
}
.city-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.city-card:hover {
  background-color: #5dade2;
}

.city-details {
  flex-grow: 1;
}

.clickable-city {
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 480px) {
  .modal-container {
    padding: 5px;
    justify-content: center;
    align-items: center;
  }
  .add-button {
    display: flex;
    flex-wrap: wrap;
    margin: 5px auto;
  }
}
